import { nanoid } from "nanoid";

// HEAD DATA
export const headData = {
	title: "Jill Marcum | Dev",
	lang: "en", // e.g: en, es, fr, jp
	description: "Welcome to my portfolio website", // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
	title: "Hi, my name is ",
	name: "Jill Marcum.",
	subtitle: "I am a full-stack developer.",
	cta: "JavaScript, React, Node.js, Express, PostgreSQL, AWS, authentication...",
};

// ABOUT DATA
export const aboutData = {
	img: "profile.jpg",
	paragraphOne:
		"Hi! I'm Jill, a JavaScript-based developer from California. In a previous life, I was a GIS Data Analyst (working with Postgres and python), as well as an archaeologist and Oxford-educated ancient historian. I read Sumerian!",
	paragraphTwo:
		"My latest project is Just Row, an app designed to increase mental health and social justice in competitive rowing. It allows for easy management of personal and team data; protecting the anonymity of teammates while giving algorithmic insights to coaches and captains. See below for more!",
	paragraphThree:
		"My last job was at the Metropolitan Museum of Art in New York, where I got my start in web development, joining the Agile dev team for UX work, testing, and making responsive front-ends. I live in terminal, and I use VSCode for all my JavaScript-based work.",
	resume: "", // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
	{
		id: nanoid(),
		img: "justrow.png",
		title: "Just Row",
		info: "A complete rowing log app for personal and team management, to reduce mental health issues, increase social justice, and foster camaraderie in competitive rowing.",
		info2:
			"React (latest tech: hooks, context, etc.), Node, Express, PostgreSQL, with Firebase authentication. All deployed with nginx on an AWS EC2 instance running ubuntu 20.04.",
		url: "https://www.justrow.co.uk",
		repo: "https://github.com/cametumbling/just-row",
	},
	{
		id: nanoid(),
		img: "smartbrain.png",
		title: "SmartBrain",
		info: "A face recognition app: create an account, input image URLs, and the app draws bounding boxes around the faces.",
		info2: "React, Node, Express, Postgres, Docker, redis, bcrypt, AWS lambdas",
		url: "https://suchasmartbrain.herokuapp.com/",
		repo: "https://github.com/cametumbling/smartbrain2_api",
	},
	{
		id: nanoid(),
		img: "wanderlust.png",
		title: "Wanderlust",
		info: "A JavaScript travel exploration front-end: enter a city (global), get back the current weather and the top three places to visit.",
		info2: "JavaScript, OpenWeather & Foursquare APIs",
		url: "https://cametumbling-wanderlust.surge.sh",
		repo: "https://github.com/cametumbling/wanderlust",
	},
	{
		id: nanoid(),
		img: "robofrenz.png",
		title: "RoboFrenz",
		info: "A searchable robot rolodex!",
		info2: "React, Robot API",
		url: "https://cametumbling-robofrenz.surge.sh/",
		repo: "https://github.com/cametumbling/robofrenz",
	},
	{
		id: nanoid(),
		title: "Burrito Builder",
		info: "A custom food ordering and order tracking website for a local burrito place. ** not currently live **",
		info2: "React (Redux, Sagas), Firebase",
		repo: "https://github.com/cametumbling/burritobuilder",
	},
];

// CONTACT DATA
export const contactData = {
	cta: "Would you like to work with me? Awesome!",
	btn: "Email me",
	email: "jill.marcum@gmail.com",
};

// FOOTER DATA
export const footerData = {
	networks: [
		{
			id: nanoid(),
			name: "linkedin",
			url: "https://www.linkedin.com/in/jill-marcum-oxford/",
		},
		{
			id: nanoid(),
			name: "github",
			url: "https://github.com/cametumbling",
		},
	],
};

// Github start/fork buttons
export const githubButtons = {
	isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
